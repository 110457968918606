<template>
  <div id="home">
    <!-- <headers></headers> -->
    <div class="activity-detail manage-wrapper">
      <Breadcrumb :name="info.name"></Breadcrumb>
      <div class="activity-container">
        <h2 class="active-title">{{info.name}}</h2>
        <h3 class="active-subtitle">
          <span>{{info.courseResource}}</span> <span>{{info.createTime}}</span>
        </h3>
        <div class="cover-box">
          <img :src="downloadURL + info.cover" alt="" class="cover"
            :onerror="$store.state.course.defaultActivityBigCover">
        </div>
        <div class="explain">
          <h3>【活动内容】</h3>
          <p v-html="info.detail"></p>
        </div>
        <div class="time">
          <h3>【活动报名时间】</h3>
          {{info.applyStartTime}} -- {{info.applyEndTime}}
        </div>
        <div class="time">
          <h3>【活动进行时间】</h3>
          {{info.activityBeginTime}} -- {{info.activityEndTime}}
        </div>
        <div class="btns">
          <button class="btn csp" :class="[btnStatusMap[info.applyStatus]? btnStatusMap[info.applyStatus].class: '']"
            @click="signUp">
            {{btnStatusMap[info.applyStatus] ? btnStatusMap[info.applyStatus].text : ''}}
          </button>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        info: {},
        defualtImage: 'this.src="' + require("@/assets/images/course/activity-cover.png") + '"',
        text: '报名',
        status: 1,
        btnStatusMap: {
          0: {
            text: '参加报名',
            class: "btn-grey"
          },
          1: {
            text: '参加报名',
            class: 'btn'
          },
          2: {
            text: '去完成',
            class: 'btn'
          },
          3: {
            text: '已完成',
            class: 'btn-white'
          }
        },
      }
    },
    methods: {
      async getActivityDetail() {
        let id = this.$route.params.id
        let resData = await this.$Api.Course.getActivityDetail(id);
        console.log(resData);
        this.info = resData.data;
      },
      async getApllySatatus() {
        let id = this.$route.params.id
        let resData = await this.$Api.Activity.getApplyStatus(id)
        // console.log(resData);
        // this.text = resData.data === 0 ? '报名' : resData.data === 1 ? '已报名' : '';
      },
      async signUp() {
        if (this.info.applyStatus == 0) {
          this.$alert('不在报名时间范围内', '提示', {
            confirmButtonText: '确定',
            callback: action => {}
          });
        } else if (this.info.applyStatus == 1) {
          let params = {
            activityId: this.$route.params.id,
            status: this.status,
          }
          let resData = await this.$Api.Activity.apply(params)
          // console.log(resData);
          if (resData.code === 200 && resData.data === '请求成功') {
            this.getActivityDetail()
          } else if (resData.code === 500 && resData.msg === '不在报名时间') {
            this.$alert('不在报名时间范围内', '提示', {
              confirmButtonText: '确定',
              callback: action => {}
            });
          }
        } else if (this.info.applyStatus == 2 && this.info.themeId) {
          this.$router.push({
            path: `/mytask/detail/${this.info.themeId}`,
            query: {
              proid: this.info.projectId,
              bindingId: this.info.id,
              theme: this.info.themeName
            }
          })
        } else if (this.info.applyStatus == 2) {
          this.$alert('暂无作业', '提示', {
            confirmButtonText: '确定',
            callback: action => {}
          });
        } else if (this.info.applyStatus == 4) {
          return
        }



      },
    },
    mounted() {
      this.getActivityDetail();
      this.getApllySatatus();
    },
  }
</script>

<style lang="less">
  #home {
    max-width: 1920px;
    min-width: 1100px;
    // transform: scale(1);
    margin: 0 auto;
  }

  .activity-detail {
    // margin: 0 auto;
    height: auto;
    background-color: #fafafa;
    overflow: hidden;
    min-height: 500px;

    .breadcrumb {
      margin: 0 auto 31px;
      padding-top: 51px;
    }

    .activity-container {
      box-sizing: border-box;
      margin-bottom: 50px;
      border-radius: 10px;
      padding: 35px 68px;
      background-color: #fff;

      .active-title {
        font-size: 20px;
        margin-bottom: 10px;
      }

      .active-subtitle {
        font-size: 16px;
        color: #858585;
        margin-bottom: 10px;

        span:first-child {
          margin-right: 10px;
        }
      }

      .cover-box {
        margin: 20px auto;
        width: 450px;

        .cover {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          border-radius: 6px;
        }
      }

      .time,
      .explain {
        h3 {
          font-size: 18px;
          text-align: center;
          margin: 10px 0;
        }

        img {
          display: block;
          margin: 15px auto;
        }

        margin: 50px 0;
        font-size: 16px;

      }

      .time {
        text-align: center;
      }

      .btns {
        text-align: center;

        .btn {
          line-height: 35px;
          width: 200px;
          //    padding: 0 75px;
          border-radius: 25px;
          background-color: #508EF9;
          // box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
          color: #fff;
        }

        .btn-grey {
          background-color: #999;
          box-shadow: none;
        }

        .btn-white {
          border: 1px solid #2373EC;
          color: #2373EC;
          background-color: #fff;
        }
      }
    }

  }
</style>